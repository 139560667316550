.account_overlay {
  max-width: 500px;
  min-height: calc(100vh - 730px);
  margin: 100px auto;

  text-align: center;
}

.account_overlay h2 {
  color: #c75300;
}

.account_overlay h2,
.account_overlay h1 {
  margin-bottom: 20px;
}

.account_overlay p {
  max-width: 60%;
  background-color: transparent !important;
  margin: 0 auto 80px;
}

.accountButtonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.accountButtonContainer button {
  width: 90%;
}

.accountButtonContainer button:first-of-type {
  min-height: 54px;
  margin-bottom: 20px;
  color: white;
  background-color: rgb(56 62 65);
}

.accountButtonContainer button:nth-of-type(2) {
  min-height: 54px;
  outline: 1px solid #f98560;
}
