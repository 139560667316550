@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;500&display=swap");
.row-header {
  margin: 0px auto;
  padding: 0px auto;
}
body {
  font-family: "Manrope" !important;
}
.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.footer {
  background-color: white !important;
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid #ede8e9;
  border-bottom: solid 1px #ede8e9;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

li {
  list-style: none !important;
}
.media-left {
  float: left;
  display: inline !important;
}

.media-body {
  float: left;
  display: inline !important;
  padding-left: 20px !important;
}

.navbar-dark,
.nav-bar1,
.dark1 {
  /*position: relative;*/
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: 101px;

  padding: 24px;

  color: var(--headerColor, black);

  background-color: var(--headerBackgroundColor, white);
  /*box-shadow: 0 2px 4px 0 var(--headerBoxShandow, var(--subBackgroundColor, black));*/
  border-bottom: solid 1px #ede8e9;
  /*mix-blend-mode: multiply;*/
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link {
  margin: 0 20px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: black !important;
}
button {
  border: none !important;
}

.btn1 {
  color: white !important;
  margin: 2px 43px 1px 12px !important;
  padding: 15px !important;
  border-radius: 5px !important;
  background-color: #c75300 !important;
}
img.Icons-Bell {
  width: 24px;
  height: 24px;
  margin: 8px 8px 0 0;
  object-fit: contain;
  position: relative;
  top: 20px;
  margin-right: 25px;
}
.usermenu button {
  position: relative;
  font-family: Manrope;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  background: none;
  color: #c75300 !important;
}
.usermenu button.btn1 {
  background: none;
  color: white !important;
}
.fa-bell-o {
  position: Relative;
  top: 10px;
  margin: 5px;
}

img.flogo {
  width: 250px;
  height: auto;
}
.footer a {
  color: #6d757e !important;
  font-family: Manrope-Regular;
  line-height: 34px !important;
}
a {
  text-decoration: none !important;
}
.leaderlogo {
  text-align: center;
}
.leaderlogo img {
  max-width: 100%;
}
.demo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 4rem;
  background-color: #595656;
  min-height: calc(101vh - 9rem);
  background-image: linear-gradient(to right, #e78fff 0%, #e65c95 100%);
}
.slideshow-wrapper {
  overflow: hidden;
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.mySlides {
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 3rem;
}
.carouselcaption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  z-index: 11;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.1) 40%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-shadow: 0 0 black;
}
.carouselimage {
  margin: 0 auto;
  vertical-align: middle;
  width: 101%;
}
.slideshow-container {
  margin: 0;
  padding: 0;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  z-index: 13;
  top: calc(51% - 21px);
  width: auto;
  padding: 2rem 3rem;
  color: #fcfafa;
  font-weight: bold;
  font-size: 3rem;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  user-select: none;
  background-color: transparent !important;
}
.next {
  right: 0;
  border-radius: 4px 0 0 4px;
}
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.text {
  color: #f2eded;
  text-align: center;
  font-size: 5vw;
  padding: 0;
  margin: 0.6rem;
}
.featuredtext {
  color: #ffffff;
  font-size: 3vw;
  padding: 0;
  margin: 0.6rem auto;
}
.numbertext {
  color: #ffffff;
  font-size: 0.76rem;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0.6rem;
}
.carouseldots {
  position: absolute;
  z-index: 13;
  top: auto;
  left: 0;
  right: 0;
  bottom: 3rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.dot {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  margin: 0 0.4rem;
  background-color: #e0dada;
  border-radius: 51%;
  display: inline-block;
  transition: background-color 0.4s ease;
}
.active,
.dot:hover {
  background-color: #ffffff;
}
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.4s;
  animation-name: fade;
  animation-duration: 1.4s;
}
@-webkit-keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 301px) {
}
.carousel-item {
  text-align: center;
  color: #000;
}

.carousel {
  background-color: #000;
  height: fit-content;
}

.carousel-item {
  background-color: #d40606;
  height: 40rem;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 10rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem !important;
  color: #fff;
  text-align: left;
  width: 42% !important;
  margin: 0 auto;
  padding: 0 auto;
}
.carousel-caption h3 {
  font-family: Manrope;
  font-size: 70px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #fff;
  background-color: transparent !important;
}
.carousel-caption p {
  font-family: Manrope;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #fff;
  width: 80% !important;
  text-shadow: 1.4px 1.4px black;
  background-color: transparent !important;
}
.carousel-caption a {
  color: white !important;
  margin: 2px 43px 1px 12px !important;
  padding: 12px 30.5px 14px !important;
  border-radius: 5px !important;
  background-color: #c75300 !important;
  top: 20px;
  position: relative;
}
.carousel-caption a .fa-solid {
  font-family: "FontAwesome" !important;
  font-weight: 900;
}

.section2 span {
  font-family: Manrope;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.5px;
  color: #383e41;
}
.s1 {
  padding: 50px;
}
.section2 h2,
.section3 h2,
.section4 h2 {
  margin: 40px;
  font-family: Manrope;
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.85px;
  text-align: center;
  color: #2d363b;
}
.section2 img {
  width: 100%;
}
.intr {
  padding-bottom: 20px;
  /* text-align: justify; */
}
.slick-prev:before,
.slick-next:before {
  color: #383e41 !important;
  font-size: 24px;
}
.eventitem {
  height: 260px;
  margin: 15px;
  padding: 17px 28px 28px;
  border-radius: 6px;
  box-shadow: 0 4px 12px 0 #f9f5ee;
  border: solid 1px #ede8e9;
  background-color: #fff;
  color: #2d363b;
}

.eventitem .eventtitle {
  height: 66px;
  margin: 31px 0 20px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.eventitem .eventdate span {
  width: 59px;
  height: 68px;
  margin: 0 135px 41px 0;
  font-family: Manrope;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.eventitem .eventdate div {
  height: 17px;
  font-family: Manrope;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
}

.eventitem:hover {
  box-shadow: 0 4px 5px 0 #f0f0f3;
  background-color: #c75300;
  color: white !important;
  cursor: pointer;
}

.section5 h5,
.section5a h5 {
  color: #2d363b !important;
  font-family: Manrope;
  font-size: 2rem;
  font-weight: normal;
  margin-top: 10%;
}

.section5 {
  height: 260px;
  padding: 40px;
  padding-top: 0px;
  margin-bottom: 70px;
  border-radius: 6px;
  background-color: #e0e0e1;
}
.section5a {
  height: 260px;
  padding: 40px;
  padding-top: 0px;
  margin-bottom: 70px;
  border-radius: 6px;
  background-color: #e0e0e1;
}
.section5 img {
  max-width: 60%;
  float: right;
  display: block;
  margin-top: 10%;
}
.section5 a {
  width: 185px;
  height: 51px;
  margin: 13px 49px 0 59px;
  padding: 15px 46px 14px;
  border-radius: 25.4px;
  background-color: #2d363b;
  color: white;
  top: -50px;
  position: Relative;
}
.left-arrow,
.right-arrow {
  background-color: #c75300 !important;
}
.tripbtn {
  text-align: center;
  width: 100%;
}

.loginb {
  position: Relative;
  font-family: Manrope;
}
.loginb button {
  background: none !important;
  color: #c75300 !important;
  /* color:#c75300!important; */
}
.loginb span::before {
  font-family: "FontAwesome" !important;
}
.userm {
  float: left;
  display: flex;
  position: relative;
  height: 80px;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.langs span {
  position: Relative;
  top: 5px;
  left: -5px;
  font-family: Manrope !important;
  font-size: 16px !important;
}
.fa-globe:before {
  font-family: "FontAwesome" !important;
}
.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-routing-alternatives-container {
  display: none !important;
}

ul.routelist {
  padding-left: 0px;
}
.routeitem {
  width: 100%;
  height: auto;
  margin: 20px 0 0;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 4px 12px 0 #f9f5ee;
  border: solid 1px #ede8e9;
  background-color: #fff;
}
.routeitem:hover {
  border: solid 1px #fff;
  background-color: #ede8e9;
}
.routeitem:hover .routetitle a {
  color: #c75300;
}

.routetitle,
.subtitle {
  float: left;
  display: block;
  width: 100%;
}

.routetitle a {
  margin: 12px 12px 10px 0;
  font-family: Manrope;
  font-size: 22.4px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2d363b;
  max-height: 48px;
  overflow: hidden;
  float: none;
  display: block;
  width: 100%;
}
.routetitle {
  margin: 12px 12px 10px 0;
  font-family: Manrope;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2d363b;
  max-height: 48px;
  overflow: hidden;
  float: none;
  display: block;
  width: 100%;
}
.routetag {
  margin: 0 12x 12px 0;
  padding: 0 9px 4px;
  border-radius: 4px;
  background-color: #dfdfe8;
  display: block;
  margin: 0 12px 12px 0;
  width: auto;
  float: left;
  clear: both;
}

.single_route {
  padding-right: 25px !important;
}

.single_route .subtitle,
.single_route .routetag {
  margin-top: 25px;
  margin-bottom: 25px;
}
.single_route .subtitle {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 300;
}
h4 {
  font-family: Manrope;
  font-size: 21px;
  font-weight: 600;
  padding: 10px;
}
.measurement {
  margin: 0 27px 4px 0;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #757575;
  display: table-cell;
  min-width: 150px;
  margin-top: 10px;
  padding-top: 10px;
  position: relative;
}
.apostasi span div {
  background-image: url("https://folegandros.dotsoft.gr/assets/images/icons-distance.png");
  background-repeat: no-repeat;
  text-align: left;
  padding: 0px 40px;
  background-position: center left;
}
.time span div {
  background-image: url("https://folegandros.dotsoft.gr/assets/images/icons-alarm-2.png");
  background-repeat: no-repeat;
  text-align: left;
  padding: 0px 40px;
  background-position: center left;
}
.measurement img {
  margin: 5px;
}
.thumb {
  width: 154px;
  height: 147px;
  margin: 0 0 0 68px;
  padding: 62px 25px 67px 33px;
  border-radius: 8px;
  float: right;
  display: inline;
}
.section4 img {
  width: 100%;
  float: left;
  display: inline;
}
.section4 {
  padding: 0 0 130px;
  background-color: #fcfbf9;
}

img.logoimg {
  height: 55px !important;
  width: auto !important;
}
s1a11 {
  object-position: bottom;
}

.mapboxgl-map {
  width: 100%;
  height: 400px;
}

.mcolumns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.mcolumn {
  flex: 20%;
  padding: 10px;
}
.mcolumn span {
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
}
.mcolumn span img {
  margin-right: 7px;
  position: relative;
  float: left;
  width: 20px !important;
}
.mcolumn span div {
  position: relative;
  float: left;
}

.navbar-dark {
  width: 100%;
  overflow: hidden; /* code added to prevent scroll */
}

.left-side {
  height: 100vh;
  background-repeat: repeat-y;
  overflow: hidden; /* code added to prevent scroll */
  position: sticky;
  top: 0;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(var(--bs-gutter-x));
  margin-left: calc(var(--bs-gutter-x));
}
.dummy {
  display: none !important;
  color: white !important;
}
#main-content {
  display: inline-block;
  height: auto;
  vertical-align: top;
  padding: 15px;
}

#side-content {
  display: inline-block;
  vertical-align: top;
}

.sidenav {
  padding: 15px;
  /*height: 2000px;*/
}

.sidenav1 {
  padding: 15px;
  /*height: 1700px;*/
}

.sidenav2 {
  padding: 15px;
  /*height: 1300px;*/
}

sidemap {
  padding: 15px;
  /*height: 1000px;*/
}

.tag1_4 {
  background-color: #008080 !important;
}

.tag1_5 {
  background-color: DodgerBlue !important;
}

.tag1_6 {
  background-color: SeaGreen !important;
}

.tag1_7 {
  background-color: Lime !important;
}

.tag1_8 {
  background-color: fuchsia !important;
}

.tag1_9 {
  background-color: Plum !important;
}

.btn a {
  color: white !important;
  margin: 2px 43px 1px 12px !important;
  padding: 12px 30.5px 14px !important;
  border-radius: 5px !important;
  background-color: #c75300 !important;
  position: relative;
}
span.fa-arrow-right {
  color: white;
  font-family: "FontAwesome";
}
.slick-slide {
  margin: 0 40px 0 0;
  padding: 0 0 35px;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 #f9f5ee;
  border: solid 1px #fff;
  background-color: #fff;
}
.slick-slide .relroute {
  padding-left: 20px;
  min-height: 190px;
}

.langs select:before {
  content: "\f0ac";
  font-family: "FontAwesome" !important;
  position: relative;
  left: -5px;
}

.navigation select {
  margin: 0 25px 0px 0px !important;
  border: none !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.navigation button {
  white-space: nowrap;
}
.headmenu {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}

/* navbar.css */
.navigation {
  height: 101px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}
.navigation-menu {
  margin-left: auto;
  width: 100%;
  height: 101px;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  margin: 0 20px 0 0;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #383e41;
  text-align: center;
}
.btn1:hover {
  background: white !important;
  color: #c75300 !important;
}

.loginb button:hover {
  background: #c75300 !important;
  color: white !important;
}
.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  display: none;
  color: white;
  z-index: 9999;
}
.hamburger:hover {
  background-color: #c75300;
}
.usermob {
  display: none;
}

.navigation-menu li > a::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  top: 5px;
  background: transparent;
  transition: all 0.3s;
}

.navigation-menu li > a:hover::after,
li > a.active-nav::after {
  width: 100%;
  background: #c75300;
}

@media screen and (max-width: 1128px) {
  .hamburger {
    display: block;
  }
  .userm {
    left: -60px !important;
  }
  .carousel-caption {
    bottom: 8rem !important;
    width: 60% !important;
  }
  .carousel-caption h3 {
    font-size: 2rem !important;
    background-color: transparent !important;
  }
}
@media screen and (max-width: 768px) {
  .loginb,
  .loginb1 {
    display: none;
  }
  .navigation select {
    z-index: 9999;
  }

  .usermob {
    display: block !important;
  }
  .btn1 {
    margin: 0px !important;
  }

  .carousel-caption {
    bottom: 2rem !important;
    left: 10px !important;
    padding: 0 auto !important;
    width: 90% !important;
  }
  /*.carousel-caption h3{font-size:16px!important;}
  .carousel-caption p{font-size:14px;}*/
}
@media screen and (max-width: 1128px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 1128px) {
  .navigation-menu ul {
    position: absolute;
    top: 101px;
    left: 0;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
}
@media screen and (max-width: 1128px) {
  .navigation-menu ul {
    display: none;
  }
}

@media screen and (max-width: 1128px) {
  .navigation-menu.expanded ul {
    display: block;
    z-index: 99999;
  }
  .navigation-menu li > a:hover::after,
  li > a.active-nav::after {
    background: transparent !important;
  }
}
@media screen and (max-width: 568px) {
  img.logoimg {
    height: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .footer div {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  img.flogo {
    position: relative;
    left: -20px;
  }
  .section5 img {
    top: -20px;
    margin-top: 0px;
    position: reltive;
  }
}

.logo {
  padding: 25px !important;
}
@media screen and (max-width: 1280px) and (min-width: 116px) {
  .btn1 {
    margin: 5px !important;
  }

  .navigation select {
    margin: 5px !important;
  }
  .navigation-menu li {
    margin: 0 0.2rem;
  }

  .section5 h5,
  .section5a h5 {
    font-size: 1.5rem;
  }
  .s1 {
    padding: 0;
  }
  .s1 img {
    width: 30%;
  }
  .section5 {
    padding-top: 30px;
  }
  .section5a h5 {
    margin-top: 3%;
  }
  .appsc img {
    width: 120px;
  }
  .section5 h5 {
    margin-top: 3%;
  }
  .section5 img {
    margin-top: 0%;
  }
}

@media screen and (max-width: 1160px) and (min-width: 1128px) {
  .btn1 {
    margin: 10px !important;
  }

  .navigation select {
    margin: 10px !important;
  }

  .navigation-menu li {
    margin: 0 0rem;
  }
  .navigation-menu li a {
    margin: 0 10px 0 0 !important;
  }
}

@media screen and (max-width: 1160px) and (min-width: 1128px) {
  .btn1 {
    margin: 10px !important;
  }

  .navigation select {
    margin: 10px !important;
  }

  .navigation-menu li {
    margin: 0 0rem;
  }
}

.log {
  padding-top: 30px !important;
  position: relative;
  margin: 17px 0 70px;
  font-family: Manrope;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.55px;
  text-align: center;
  color: #98a2ad;
}
.tripmsg {
  margin: 17px 0 70px;
  font-family: Manrope;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.55px;
  text-align: center;
  color: #98a2ad;
}
.p {
  padding: 40px;
  background-color: transparent !important;
  color: #737373;
}
.routedate {
  color: #98a2ad !important;
}
#main-content h2 {
  padding: 40px 0px;
}
.tripmsg button {
  font-size: 18px !important;
}

.btn2 {
  color: white !important;
  margin: 2px 43px 1px 12px !important;
  padding: 15px !important;
  border-radius: 5px !important;
  background-color: #c75300 !important;
}
.btn2:hover {
  opacity: 0.8;
}
span.addbtn {
  height: 45px;
  width: 45px;
  background-color: #c75300;
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
  font-size: 25px;
  vertical-align: middle;
  line-height: 45px;
}
.abtn {
  text-align: center;
  position: relative;
  top: -40px;
  cursor: pointer;
}
ul.ulp li {
  list-style: disc !important;
}
ol.ulp li {
  list-style-type: lower-roman;
}
.sectionp {
  text-align: justify;
  width: 80%;
  padding: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
}

.sectionp h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sectionp1 p {
  margin-top: 1rem !important;
  background-color: transparent !important;
}

.tripup {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.tripup .routetitle {
  display: inline !important;
  float: left !important;
  width: auto !important;
}

.tripup .tripbtns,
.abtn11 {
  display: inline !important;
  float: right !important;
  width: auto !important;
}

.addbtn11 img {
  cursor: pointer;
  margin-left: 10px;
}
.addbtn11 img:hover {
  -webkit-filter: saturate(410%);
  filter: saturate(410%);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: 2px solid #0074ff;
}
.tripcal li button {
  margin: 0 10px 1px;
  padding: 25px 24px 25px 25px;
  border-radius: 12.6px !important;
  border: solid 1px #e6e6e6 !important;
}

.tripcal li button.active,
.tripcal li button:hover {
  border: solid 1px #c75300 !important;
  background-color: #fffaf6 !important;
  color: #c75300 !important;
}
.tripcal li button li {
  width: 40px;
  word-wrap: normal;
  font-family: Manrope;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: center;
  color: #98a2ad;
}
.tripcal .nav-tabs {
  border-bottom: none !important;
}

.addr {
  font-family: Manrope;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: 0.66px;
  color: #98a2ad;
}
.tripcal {
  margin-bottom: 30px;
  margin-top: 60px;
}

.tripcal .tab-content {
  margin-top: 40px !important;
}
.add2trip li,
.add2trip label {
  float: left;
  width: 100%;
  display: block;
  margin: 10px 0px;
}

.imgsection {
  background-image: url("https://folegandros.mitos.cityguideplatform.com/instance_images/folegandros/TGCLaK9lPFkUlmRzRzemG2qGDrKAadqjXnYb5se7.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  font-family: Manrope;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.5px;
  color: transparent;
  background-size: cover;
  margin-top: 10px;
}

.main_description {
  font-size: 16px !important;
  font-family: "Manrope" !important;
  line-height: 1.5rem !important;
}
.leb {
  font-size: 1.5rem;
  color: #393939;
  font-family: Manrope !important;
  width: 100%;
  text-align: center;
  font-weight: bolder !important;
}

.mbtn {
  width: 100%;
  text-align: center;
  font-weight: bolder !important;
  color: white !important;
  background: #c75300;
  padding: 10px;
  border-radius: 5px;
  float: left !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.mbtn1 {
  width: 45%;
  text-align: center;
  font-weight: bolder !important;
  color: white !important;
  background: #c75300;
  padding: 10px;
  border-radius: 5px;
  float: left !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
  margin-left: 20px !important;
}
.tripcal li {
  margin-bottom: 20px;
}

.react-datepicker {
  width: 100% !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.6rem !important;
  line-height: 2.6rem !important;
  font-family: "Manrope" !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 1.5rem !important;
  background-color: #fa6901 !important;
  color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 1.5rem !important;
  background-color: #fa6901 !important;
  color: #fff;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 1.5rem !important;
  background-color: #fa6901 !important;
  color: #fff;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.mbod {
  margin: 17px 0 67px !important;
  font-family: Manrope !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.35 !important;
  letter-spacing: 0.55px !important;
  text-align: center !important;
  color: #98a2ad !important;
}
.tripbtn a:hover {
  opacity: 0.8;
}

.sep {
  border-bottom: 5px solid #c75300;
  color: #c75300 !important;
}
.kdown a {
  padding: 10px 25px 10px 15px;
  border-radius: 4px;
  border: solid 0.8px #ff663b;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #fff6ef, #fff6ef);
  color: #c75300 !important;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.kdown a:hover {
  color: #fff6ef !important;
  background: #c75300 !important;
}

.kdown a:hover img {
  filter: brightness(0) invert(1);
}

#bibl button {
  padding: 10px 25px 10px 15px;
  border-radius: 4px;
  border: solid 0.8px #ff663b;
  background-blend-mode: multiply;
  /*background-image: linear-gradient(to bottom, #fff6ef, #fff6ef);
  color: #c75300!important;*/
  color: #fff6ef !important;
  background-color: #ff663b;
  font-size: 18px !important;
}

#bibl .accordion-button:not(.collapsed)::after {
  color: #fff6ef !important;
}
.MuiPaper-root {
  width: 50%;
  margin: 0 auto;
}
.gal {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
#bibl {
  padding-bottom: 30px;
}

@media only screen and (max-width: 780px) {
  .MuiPaper-root {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 991px) {
  #side-content,
  #side-content div div:first-child {
    position: relative !important;
  }
  #side-content {
    height: 100vh !important;
  }
}

.react-player {
  display: grid;
  float: left;
  margin: 10px;
}
.did_you_know h3 {
  text-align: center;
  background-color: transparent !important;
}
.appsc img {
  margin-right: 10px;
}

.appsc1 {
  padding-top: 20px;
}
.apps2 img {
  width: 35px;
  height: auto;
  margin: 0 20px;
}
.singlevideo {
  width: 30%;
  position: relative;
  display: inline;
  float: left;
  margin: 10px;
}
.videos {
  text-align: center;
  padding: 0 auto;
}
